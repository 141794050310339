import React, { useState } from 'react';
import { Wrapper } from './GridViewController.css';
import { GridView, List } from '@mui/icons-material';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from '../DashboardGalleryFilters/DashboardGalleryFilters.css';

export enum DashboardsViewTypeEnum {
  GRID = 'GRID',
  LIST = 'LIST',
}

export interface GridViewControllerProps {
  dashboardsViewType: DashboardsViewTypeEnum;
  onGridViewChange: (dashboardsViewType: DashboardsViewTypeEnum) => void;
}

const GridViewController = ({ dashboardsViewType, onGridViewChange }: GridViewControllerProps) => {
  const [gridViewType, setGridViewType] = useState<DashboardsViewTypeEnum>(dashboardsViewType);

  const onChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, type: DashboardsViewTypeEnum) => {
    e.stopPropagation();
    setGridViewType(type);
    onGridViewChange(type !== null ? type : DashboardsViewTypeEnum.LIST);
  };

  return (
    <Wrapper>
      <StyledToggleButtonGroup value={gridViewType} exclusive onChange={onChange}>
        <StyledToggleButton value={DashboardsViewTypeEnum.GRID} size="small" color="primary">
          <GridView />
        </StyledToggleButton>
        <StyledToggleButton value={DashboardsViewTypeEnum.LIST} size="small" color="primary">
          <List />
        </StyledToggleButton>
      </StyledToggleButtonGroup>
    </Wrapper>
  );
};

export default GridViewController;
