import { Bookmark, BookmarkAddOutlined } from '@mui/icons-material';
import { DashboardsViewTypeEnum } from 'components/ui/GridViewController/GridViewController';
import Link from 'next/link';
import React, { ChangeEvent, useState } from 'react';
import { ClientDashboardModel } from '../../../models';
import {
  DashboardPlaceholder,
  GalleryCardContent,
  StyledCard,
  StyledCheckbox,
  StyledTypography,
} from './DashboardGalleryCard.css';

const DashboardGalleryCard = ({
  dashboardsViewType,
  onBookmarkClick,
  onClientDashboardClick,
  clientDashboardModel,
}: DashboardGalleryCardProps) => {
  const { bookmarked, dashboardModel, dashboardRefLink, dashboardName, dashboardImage } =
    clientDashboardModel;
  const [checked, setChecked] = useState<boolean>(Boolean(bookmarked));
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onBookmarkClick(clientDashboardModel.clientDashboardUuid, e.target.checked);
  };

  const [raised, setRaised] = useState<boolean>(false);
  const toggleRaised = () => setRaised(!raised);

  return (
    <Link href={dashboardRefLink ? dashboardRefLink : ''} passHref>
      <StyledCard
        className="styledCard"
        dashboardsViewType={dashboardsViewType}
        onClick={() => onClientDashboardClick(dashboardModel!.dashboardUuid)}
        raised={raised}
        onMouseEnter={toggleRaised}
        onMouseLeave={toggleRaised}
      >
        <DashboardPlaceholder dashboardsViewType={dashboardsViewType} imageUrl={dashboardImage} />
        <GalleryCardContent>
          <StyledTypography variant="subheadingSmall" onClick={(e) => e.stopPropagation()}>
            {dashboardName ? dashboardName.toUpperCase() : ''}
          </StyledTypography>
          <StyledCheckbox
            checked={checked}
            icon={<BookmarkAddOutlined />}
            checkedIcon={<Bookmark />}
            disableRipple
            onChange={handleChange}
            onClick={(e) => e.stopPropagation()}
          />
        </GalleryCardContent>
      </StyledCard>
    </Link>
  );
};

export interface DashboardGalleryCardProps {
  dashboardsViewType: DashboardsViewTypeEnum;
  clientDashboardModel: ClientDashboardModel;
  onBookmarkClick: (clientDashboardUuid: string, isBookmarked: boolean) => void;
  onClientDashboardClick: (dashboardUuid: string) => void;
}

export default DashboardGalleryCard;
