export interface ISorter<T> {
  property: Extract<keyof T, string | number | Date>;
  isDescending: boolean;
}
export function sortObjectsByProperty<T>(items: T[], sorters: ISorter<T>[]) {
  return [...items].sort((a, b) => {
    for (let sorter of sorters) {
      const comparison =
        a[sorter.property] > b[sorter.property]
          ? 1
          : a[sorter.property] < b[sorter.property]
          ? -1
          : 0;
      if (comparison !== 0) {
        return sorter.isDescending ? comparison * -1 : comparison;
      }
    }
    return 0;
  });
}
